import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { inviteUserFriend } from "../redux/slice/rewardSlice";
import { Divider, message } from "antd";
import ListReferralsTableList from "./ReferTab/MyReferralTableList";
import {
  FaFacebookF,
  FaWhatsapp,
  FaXTwitter,
 
} from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import DashboardHeader from "./components/DashboardHeader";
import DashboardTabbar from "./components/DashboardTabbar";

const Referral = () => {
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [diviseplatform, setdiviseplatform] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const userData = localStorage.getItem("user");
   
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);
  
  const userinfo = user?.data?.user;
  const referralLink = `https://www.websamp.com/?referrer=${userinfo?.referral_code}`;

  useEffect(() => {
    const userAgent = navigator.userAgent;
    setdiviseplatform(userAgent)
    window.scrollTo(0, 0);
  }, []);
console.log("userAgent",diviseplatform)
  const sendInvite = (email) => {
    dispatch(inviteUserFriend({ emails: [email] }));  
  };

  const handleSubmit = (e) => {
    e.preventDefault(); 
    sendInvite(email);  
    setEmail("");  
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink).then(
      () => {
        message.success("Referral link copied to clipboard!");
      },
      () => {
        message.error("Failed to copy referral link.");
      }
    );
  };

  const shareOnSocialMedia = (platform) => {
    const encodedLink = encodeURIComponent(referralLink);
    let url = "";
    switch (platform) {
      case "facebook":
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`;
        break;
      case "facebookMobile":
        url = `fb://facewebmodal/f?href=https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`;
        break;
      case "whatsapp":
        url = `https://web.whatsapp.com/send?text=${encodedLink}`;
        break;
      case "whatsappMobile":
        url = `whatsapp://send?text=${encodedLink}`;
        break;
      case "twitter":
        url = `https://twitter.com/intent/tweet?url=${encodedLink}`;
        break;
      case "twitterMobile":
        url = `twitter://post?message=${encodedLink}`;
        break;
      case "telegram":
        url = `https://t.me/share/url?url=${encodedLink}`;
        break;
      case "telegramMobile":
        url = `tg://msg_url?url=${encodedLink}`;
        break;
      default:
        break;
    }
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div>
       <DashboardHeader />
      
     <div className="container mt-4 ">
        <DashboardTabbar />
      
        <div className="card p-4 main-referral-card">
          <div className="ref-card-banner position-relative">
            <div className="img h-100">
              <img src="./images/ref-banner1.jpg" alt="Referral Banner" />
            </div>
            <div className="ref-text">
              <h2 className="h3 fw-bold">
                It pays to have a network! <br /> Refer Now!
              </h2>
            </div>
          </div>

          <div className="text-ref">
            <h3 className="mb-0">
              Get <span>10%</span> of your friends’ survey earnings when they
              sign up on Websamp using your referral code
            </h3>
          </div>
          <Divider />
          <div className="email-referral text-center pb-3">
            <div className="mail-icon">
              <img src="./images/mail.png" alt="Mail Icon" />
            </div>
            <h5 className="py-3 fw-bold">Invite Your Friends</h5>
            <div className="email-ref-wrapper ref-wrapper">
              <form onSubmit={handleSubmit}>
                <input
                  className="inp"
                  type="email"
                  placeholder="Enter Email"
                  value={email}  
                  onChange={(e) => setEmail(e.target.value)}  
                  required  
                />
                <button className="btn" type="submit">
                  Invite
                </button>
              </form>
            </div>
          </div>
          <Divider>OR</Divider>
          <div className="email-referral text-center py-3">
            <h4 className="h5 mb-5 fw-bold">Share Referral Link</h4>
            <div className="social-referral-wrapper">
              <ul className="social-media-buttons">
                <li className="d-md-inline-block d-none">
                  <button
                    onClick={() => shareOnSocialMedia("facebook")}
                    className="nav-link facebook"
                  >
                    <FaFacebookF className="icon" /> Facebook  
                  </button>
                </li>
                <li  className="d-md-none d-inline-block">
                  <button
                    onClick={() => shareOnSocialMedia("facebookMobile")}
                    className="nav-link facebook"
                  >
                    <FaFacebookF className="icon" /> Facebook  
                  </button>
                </li>
                <li className="d-md-inline-block d-none">
                  <button
                    onClick={() => shareOnSocialMedia("whatsapp")}
                    className="nav-link whatsapp"
                  >
                    <FaWhatsapp className="icon" /> WhatsApp  
                  </button>
                </li>
                <li className="d-md-none d-inline-block">
                  <button
                    onClick={() => shareOnSocialMedia("whatsappMobile")}
                    className="nav-link whatsapp"
                  >
                    <FaWhatsapp className="icon" /> WhatsApp  
                  </button>
                </li>
                <li className="d-md-inline-block d-none">
                  <button
                    onClick={() => shareOnSocialMedia("twitter")}
                    className="nav-link twitter"
                  >
                    <FaXTwitter className="icon" /> Twitter  
                  </button>
                </li>
                <li  className="d-md-none d-inline-block">
                  <button
                    onClick={() => shareOnSocialMedia("twitterMobile")}
                    className="nav-link twitter"
                  >
                    <FaXTwitter className="icon" /> Twitter  
                  </button>
                </li>
                <li className="d-md-inline-block d-none">
                  <button
                    onClick={() => shareOnSocialMedia("telegram")}
                    className="nav-link telegram"
                  >
                    <FaTelegramPlane className="icon" /> Telegram  
                  </button>
                </li>
                <li  className="d-md-none d-inline-block">
                  <button
                    onClick={() => shareOnSocialMedia("telegramMobile")}
                    className="nav-link telegram"
                  >
                    <FaTelegramPlane className="icon" /> Telegram  
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <Divider>OR</Divider>
          <div className="email-referral text-center py-3">
            <h5 className="mb-5 fw-bold">Invite Your Friends</h5>
            <div className="email-ref-wrapper ref-wrapper">
              <form>
                <p className="mb-0 px-3">{userinfo?.referral_code}</p>
                <button type="button" className="btn" onClick={copyToClipboard}>
                  Copy
                </button>
              </form>
            </div>
          </div>
        </div>
       <ListReferralsTableList />
      </div>
    </div>
  );
};

export default Referral;
