import React, { useState, useEffect } from "react";
import { NavLink, Outlet } from "react-router-dom";
import Sidenav from "../dashboard/SideNav";
import ExtraInfo from "../dashboard/components/ExtraInfo";
import { useSelector, useDispatch } from "react-redux";
import { CgProfile } from 'react-icons/cg';
import { fetchMe} from "../redux/slice/authSlice";
import { MdRedeem } from "react-icons/md";
import { RiMoneyDollarCircleLine, RiUserSharedLine, RiSurveyLine } from "react-icons/ri";
const DashboardLayout = () => {
  const dispatch = useDispatch();
  const { personalDetail } = useSelector(({ auth }) => auth);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(fetchMe({}));
     updateLocalStorage()
  }, [dispatch]);
  const updateLocalStorage = () => {
    
    const storedUser = JSON.parse(localStorage.getItem("user"));
  
    console.log("User updated");
  
    const updatedUser = {
      ...storedUser,  
      data: {
        ...storedUser?.data,   
        user: {
          ...storedUser?.data?.user,   
          ...personalDetail,  
        },
      },
    };
    localStorage.setItem("user", JSON.stringify(updatedUser));
  };
  const isOpen = useSelector((state) => state.sidebar.isOpen);

  return (
    <div>
      <Sidenav />
      <div className="main-content-wrapper">
        <div className={isOpen ? "left" : "left close-left"}></div>
        <div className={isOpen ? "right" : "right max"}>
          
          <div className="page-content">
           
            <Outlet />
          </div>
     

<div className="bottom-nav">
  {/* Profile Tab */}
  <NavLink
    to="/profile-survey"
    className={({ isActive }) =>
      `nav-item ${isActive ? "active" : ""}`
    }
  >
    <div className="icon">
      <CgProfile size={24} />
    </div>
    <span>Profile</span>
  </NavLink>

  {/* Survey Tab */}
  <NavLink
    to="/survey"
    className={({ isActive }) =>
      `nav-item ${isActive ? "active" : ""}`
    }
  >
    <div className="icon">
      <RiSurveyLine size={24} />
    </div>
    <span>Survey</span>
  </NavLink>

  {/* Refer Tab */}
  <NavLink
    to="/refer"
    className={({ isActive }) =>
      `nav-item ${isActive ? "active" : ""}`
    }
  >
    <div className="icon">
      <RiUserSharedLine size={24} />
    </div>
    <span>Refer</span>
  </NavLink>

  {/* Earning Tab */}
  <NavLink
    to="/earning"
    className={({ isActive }) =>
      `nav-item ${isActive ? "active" : ""}`
    }
  >
    <div className="icon">
      <RiMoneyDollarCircleLine size={24} />
    </div>
    <span>Earning</span>
  </NavLink>

  {/* Redeem Tab */}
  <NavLink
    to="/reward"
    className={({ isActive }) =>
      `nav-item ${isActive ? "active" : ""}`
    }
  >
    <div className="icon">
      <MdRedeem size={24} />
    </div>
    <span>Redeem</span>
  </NavLink>
</div>

    

          <div className="dashboard-footer py-3 mt-4">
            <p className="text-center mb-0">Websamp Tool © Continuum Insight</p>
          </div>
        </div>
      </div>
      <ExtraInfo />
  
    </div>
  );
};

export default DashboardLayout;
