import axios from "axios";
import { canonicalizeResponse, authHeader } from "./common";

const userAssignedSurvey = (body) => {
    return axios
      .post(
        process.env.REACT_APP_BASE_API_URL + "/v1/api/campaign/user/surveys",
        body,
        { headers: authHeader() }
      )
      .then((response) => canonicalizeResponse(response));
};

export default {
  userAssignedSurvey
};
