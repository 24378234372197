import React from "react";
import { FaBarsStaggered } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../../redux/slice/sidebarSlice";

const DashboardBanner = () => {
  const dispatch = useDispatch();
  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  
  return (
    <div className="dash-content-wrapper dashboard-header">
      <div className=" px-sm-5 px-3 n-wrapper  d-flex align-items-center justify-content-between">
        <div className="dashboard-header-logo">
          <img src="./logo/websamp.png" alt="Big Logo" />
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="sidenav-open" onClick={handleToggleSidebar}>
            <FaBarsStaggered />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardBanner;
