
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';

import App from './App';
import './index.css';
import { Auth0Provider } from '@auth0/auth0-react';
import { GoogleOAuthProvider } from '@react-oauth/google';
 const clintid = process.env.REACT_APP_GOOGLE_CLIENT_ID

 ReactDOM.render(
  <GoogleOAuthProvider clientId={clintid}>
  <Provider store={store}>
    <App />
  </Provider>
  </GoogleOAuthProvider>,
  document.getElementById('root')
);

 
// ReactDOM.render(
//   <Auth0Provider
//   domain="dev-ej2t4tdhsmozkgr8.us.auth0.com"
//   clientId="wobvACrnZZyA89397JG8sOKw9R8k65E8"
//   authorizationParams={{
//     redirect_uri: window.location.origin
//   }}
// >
//   <GoogleOAuthProvider clientId={clintid}>
//   <Provider store={store}>
//     <App />
//   </Provider>
//   </GoogleOAuthProvider>
//   </Auth0Provider>,
//   document.getElementById('root')
// );
