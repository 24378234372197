import React, {useEffect} from "react";
import FAQ from "./components/FAQ";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <main>
        <div className="container py-5">
          <div className="row d-flex align-items-center">
             <div className="col-md-6 pe-md-5">
              <div className="wrapper">
                <div>
                  <div className="section-title text-start mb-4 ">
                    <h2 className="whiteColor">Your Voice, Your Value</h2>
                  </div>
               
                  <p className="value-two__text text-start">
                  WebSamp is more than just a survey platform; it's a community where your opinion matters. We connect you with businesses and organizations eager to hear your thoughts on a variety of topics. By sharing your insights, you not only contribute to shaping products and services but also earn valuable rewards.
                  </p>
                  <p className="text-start">Our mission is to empower individuals to have a voice in the marketplace. We believe that everyone's opinion is important and should be heard. Join our panel today and start making a difference while earning exciting rewards. Your journey to becoming a valued contributor begins here.</p>
                 
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="wrapper">
              <div className="img fix-height-img">
                  <img className="w-100 h-auto" src="./images/about-us.jpg" alt="" />
                </div>
              </div>
            </div>
           
          </div>
        </div>

     
      </main>
    </div>
  );
};

export default About;
