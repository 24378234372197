import React from "react";

const Blog = () => {
  return (
    <div>
      <div className="blog-bg">
        <div className="container">
          <div className="blog-detail-wrapper pb-5">
            <div className="detail-wrapper pt-1">
              <div className="blog-wrapper-banner mt-5">
                <div className="img">
                  <img src="/images/home-banner7.jpg" alt="Cati-Survey" />
                </div>
                <div className="wrapper pt-5">
                  <h1 className="">
                    Accelerate Your Research with Continuum Insights' AI-Powered
                    Data Collection
                  </h1>
                </div>
              </div>
            </div>

            <div className="content">
              <p>
                In today's fast-paced business environment, timely and accurate
                data is essential for informed decision-making. Continuum
                Insights offers a cutting-edge solution to help you gather the
                insights you need to drive growth.
              </p>
            </div>

            <div className="content">
              <h2>Leverage AI for Superior Data Collection</h2>
              <p>
                Our AI-driven platform empowers you to collect high-quality data
                efficiently and effectively. Here's how:
              </p>
              <p>
                <strong>Precision:</strong>
                Our advanced algorithms identify the ideal respondent pool for
                your research, ensuring data accuracy and relevance.
              </p>
              <p>
                {" "}
                <strong>Rapid Data Collection:</strong> Streamline your research
                process with our automated data collection tools, reducing
                time-to-insights.
              </p>
              <p>
                <strong>Enhanced Data Quality:</strong>
                AI-powered quality checks identify and eliminate fraudulent or
                low-quality responses, ensuring data integrity.
              </p>
              <p>
                {" "}
                <strong>Global Reach:</strong>
                Access diverse respondent populations worldwide through our
                extensive network.
              </p>
              <p>
                <strong>Real-Time Insights:</strong>
                Gain immediate access to preliminary data for quick adjustments
                and informed decision-making.
              </p>
            </div>
            <div className="content">
              <h2>Benefits of Partnering with Continuum Insights:</h2>
              <p>
                <strong>Cost-Effective:</strong>
                Our AI-driven solutions optimize your research budget by
                reducing manual effort and increasing efficiency.
              </p>
              <p>
                <strong> Scalability:</strong>
                Handle projects of any size with our flexible platform.
              </p>
              <p>
                <strong>Expert Support:</strong>
                Our team of experienced researchers is dedicated to your
                success.
              </p>
              <p>
                <strong>Data Security:</strong>
                We prioritize data privacy and security to protect your
                sensitive information.
              </p>
            </div>
            <div className="content ">
              <div className="comment-wrapper">
                <form action="">
                  <div class="mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label">
                      Type your comment here.
                    </label>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="5"
                    ></textarea>
                  </div>
                  <button type="submit" class="btn ">
                    {" "}
                    Comment
                  </button>
                </form>
              </div>
            </div>
            <div className="content  border-top">
              <h4 className="mt-5">All comments(31)</h4>
              <div className="comment-item">
                <div className="comment ">
                  <p>
                    <span className="name">rj yadav</span>{" "}
                    <span className="time"> Today 07:18</span>
                  </p>
                </div>
                <p>
                  You can uninstall the pre-installed apps, except for an app.
                  Might be OnePlus did for the cost cutting.
                </p>
                <div className="comment ">
                  <p>
                    
                    <span className="time"> Today 07:18</span>
                    <span>Replay</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
