import { Card, Col, Collapse, Row } from 'antd';
import React from 'react';
const { Panel } = Collapse;

const FAQ = () => {
  const onChange = (key) => {
    console.log(key);
  };
  return (
    <>
   
        <div id='faq' className=" faq">
          <div className="heading">
            <h2 className=""><span>FAQ's</span> </h2>
            <p>
              Websamp is a great and simple way to earn rewards. Below you’ll find answers to the most frequently asked questions. We periodically update this section. If you have a question and cannot find the answer among those listed below, you can fill our contact form on the contact page.
            </p>
          </div>
          <Row gutter={24}>
  <Col xs={{ span: 24 }}>
    <Card
      hoverable
      style={{ borderRadius: "16px", marginTop: "20px", backgroundColor: '#9dc1f21f' }}
    >
      <div className="semiBold font18 mb-20">MEMBERSHIP</div>

      <Collapse
        bordered={false}
        defaultActiveKey={['1']}
        onChange={onChange}
        style={{ backgroundColor: "#9dc1f21f" }}
      >
        <Panel className="font16" header="HOW DO I JOIN?" key="1">
          <p>
            You can sign up using your Google, Apple, LinkedIn, or Twitter account.  Please note that we don’t allow multiple email addresses for the same user in the panel.

          </p>
        </Panel>
       
        <Panel className="font16" header="HOW CAN I UPDATE MY PERSONAL INFORMATION?" key="3">
          <p>
            Personal information is important, and we value your trust in sharing it with us. If you need to update your information, you can do so by logging in to your account. 
          </p>
        </Panel>
        <Panel className="font16" header="HOW DO I CLOSE MY WEBSAMP ACCOUNT?" key="4">
          <p>
            If you wish to close your Websamp account, you can log in to your account and delete your account.
          </p>
        </Panel>
      </Collapse>
    </Card>

    <Card
      hoverable
      style={{ borderRadius: "16px", marginTop: "20px", backgroundColor: '#9dc1f21f' }}
    >
      <div className="semiBold font18 mb-20">REWARDS</div>

      <Collapse bordered={false} onChange={onChange} style={{ backgroundColor: "#9dc1f21f" }}>
        <Panel className="font16" header="HOW DO I REQUEST REWARDS?" key="1">
          <p>
            Log in to your dashboard via the Login button. Click on "Redeem," choose the rewards you want, and follow the steps shown on your screen.
          </p>
        </Panel>
        <Panel className="font16" header="MY REWARDS AREN'T SHOWING – HELP?" key="2">
          <p>
            Once you request rewards, our backend team verifies the request and processes it within 7-10 business days. You can always check the status of your request on your dashboard.
          </p>
        </Panel>
        <Panel className="font16" header="HOW DO I USE MY REWARDS?" key="3">
          <p>
            You can use your rewards with our partnered brands, which include many  companies in India across fashion, accessories, restaurants, lifestyle, and experiences. More information is available in the "Incentives" section of our website.
          </p>
        </Panel>
      </Collapse>
    </Card>

    <Card
      hoverable
      style={{ borderRadius: "16px", marginTop: "20px", backgroundColor: '#9dc1f21f' }}
    >
      <div className="semiBold font18 mb-20">SURVEYS</div>

      <Collapse bordered={false} onChange={onChange} style={{ backgroundColor: "#9dc1f21f" }}>
        <Panel className="font16" header="WHY DO I SOMETIMES ONLY ANSWER A FEW QUESTIONS?" key="1">
          <p>
            Every survey has different objectives and is looking for specific information. Some surveys are targeted at particular groups, and if your responses don’t match the criteria, you might be opted out early.
          </p>
        </Panel>
        <Panel className="font16" header="HOW IS MY DATA HANDLED?" key="2">
          <p>
            Confidentiality is our priority. Your data is kept anonymous and is used in a compiled form for survey results. No personal details are shared with clients or external parties.
          </p>
        </Panel>
        <Panel className="font16" header="WHAT REWARDS DO I GET FOR COMPLETING A SURVEY?" key="3">
          <p>
            Rewards vary depending on the survey length and type. You must complete the entire survey to be eligible for a reward.
          </p>
        </Panel>
        <Panel className="font16" header="HOW OFTEN WILL I RECEIVE SURVEYS?" key="4">
          <p>
            The frequency of surveys depends on the information you’ve provided and the number of surveys currently available. You can set a limit on how many surveys you receive per month via your dashboard.
          </p>
        </Panel>
        <Panel className="font16" header="HOW CAN I PARTICIPATE IN SURVEYS?" key="5">
          <p>
            We’ll send survey links to the email address you’ve registered with us. Clicking the link will take you to a secure page to complete the survey and claim your reward.
          </p>
        </Panel>
        <Panel className="font16" header="WHY DOES IT SAY THE SURVEY HAS ENDED?" key="6">
          <p>
            Every survey has a limited timeframe for completion. If you click the survey link after the time has expired, you’ll see a message stating the survey has ended.
          </p>
        </Panel>
      </Collapse>
    </Card>

    <Card
      hoverable
      style={{ borderRadius: "16px", marginTop: "20px", backgroundColor: '#9dc1f21f' }}
    >
      <div className="semiBold font18 mb-20">PRIVACY AND SECURITY</div>

      <Collapse bordered={false} onChange={onChange} style={{ backgroundColor: "#9dc1f21f" }}>
        <Panel className="font16" header="IS MY PERSONAL INFORMATION SAFE?" key="1">
          <p>
            We take data privacy seriously. Your personal information is protected and will never be shared with third parties without your consent.
          </p>
        </Panel>
        <Panel className="font16" header="HOW DO YOU USE MY SURVEY RESPONSES?" key="2">
          <p>
            Your survey responses are used to provide insights to our clients. Your individual responses are kept confidential and anonymous.
          </p>
        </Panel>
      </Collapse>
    </Card>
  </Col>
</Row>

        </div>
    

    </>

  );
};
export default FAQ;