import React, { useEffect } from "react";
import FAQ from "./components/FAQ";

const FAQPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <main>
        <section>
          <div className="container py-5">
            <FAQ />
          </div>
        </section>
      </main>
    </div>
  );
};

export default FAQPage;
