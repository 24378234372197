import React, { useState, useEffect } from "react";
import "antd/dist/reset.css"; // Import Ant Design styles
import Redeem from "./components/Redeem";
import DashboardHeader from "./components/DashboardHeader";
import DashboardTabbar from "./components/DashboardTabbar";
import {  message } from "antd";
const Reward = () => {
  const [extrainfo, setExtraInfo] = useState(false); // Start with false to hide Redeem initially
  const [selectedReward, setSelectedReward] = useState(null);
  const [user, setUser] = useState(null);
  
  useEffect(() => {
    const userData = localStorage.getItem("user");
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  const userinfo = user?.data?.user;

  const rewardData = [
    {
      img: "./images/reward1.png",
      title: "UPI",
      rewardPrice: "₹1000",
      points: 100000,
    },
    {
      img: "./images/reward2.jpg",
      title: "Payoneer",
      rewardPrice: "₹1000",
      points: 100000,
    },
    {
      img: "./images/reward3.jpg",
      title: "Amazon INR",
      rewardPrice: "₹1000",
      points: 100000,
    },
    {
      img: "./images/reward4.jpg",
      title: "Flipkart",
      rewardPrice: "₹1000",
      points: 100000,
    },
  ];

  const handleRewardClick = (reward) => {
    const userPoints = userinfo?.balance?.WSP ?? 0;

    if (userPoints < 100000) {
      message.error(`You need a minimum of 100000 points to redeem a reward. Selected Reward: ${reward.title.toUpperCase()}`);

    } else {
      setSelectedReward(reward);
      setExtraInfo(true);
    }
  };

  const redeemtoggle = () => {
    setExtraInfo((prev) => !prev);
  };

  return (
    <div>
       <DashboardHeader />
      
      <div className="container mt-4 min-h">
        <DashboardTabbar />
        <div className="card p-4">
          <div className="ref-card-banner position-relative">
            <div className="img h-100">
              <img src="./images/ref-banner.png" alt="Redeem Banner" />
            </div>
            <div className="ref-text">
              <h2 className="h3 fw-bold">
                Redeem Your Rewards
                <br />
                With Ease!
              </h2>
            </div>
          </div>

          <div className="text-ref">
            <h3 className="mb-0">
              You can redeem your reward with ease using gift vouchers or cash
              rewards.
            </h3>
          </div>

          <h3 className="h5 mt-5">
            Your current point is{" "}
            <span className="color1">
              {userinfo?.balance?.WSP}
              <span className="wsp"> WSP </span>
            </span>
          </h3>
          <hr />
          <div className="row">
            {rewardData.map((item, index) => (
              <div
                className="col-lg-3 col-md-4 col-6  mt-4"
                onClick={() => handleRewardClick(item)}
                key={index}
              >
                <div className="reward-wrapper">
                  <div className="ri-wrapper">
                    <div className="img">
                      <img src={item.img} alt={item.title} />
                    </div>
                  </div>

                  <div className="text">
                    <p className="h5">{item.title}</p>
                    <p>
                      <strong>{item.rewardPrice}</strong>
                    </p>
                    <p> WSP: {item.points} </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {extrainfo && (
        <Redeem reward={selectedReward} redeemtoggle={redeemtoggle} />
      )}
    </div>
  );
};

export default Reward;
