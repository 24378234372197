import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import surveyService from '../../services/surveyService';
import { notification } from 'antd';

// Async Thunks
export const getUserSurveyList = createAsyncThunk(
  'survey/getUserSurveyList',
  async (body, { rejectWithValue }) => {
    try {
      const response = await surveyService.userAssignedSurvey(body);
      if (response?.data?.error) throw new Error(response.data.message);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Slice
const surveySlice = createSlice({
  name: 'survey',
  initialState: {
    surveys: {},
    fetching: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserSurveyList.pending, (state) => {
        state.fetching = true;
        state.error = null;
      })
      .addCase(getUserSurveyList.fulfilled, (state, action) => {
        state.surveys = action.payload;
        state.fetching = false;
      })
      .addCase(getUserSurveyList.rejected, (state, action) => {
        state.fetching = false;
        state.error = action.payload;
      });
  }
});

export default surveySlice.reducer;
