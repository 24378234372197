import React from "react";
import { NavLink } from "react-router-dom";

const Discussion = () => {
  return (
    <div>
      <div className="container">
        <h2 className="h4">Your current point is 0 </h2>
        <div className="row">
          {[
            {
              img: "./images/getGiftImage.png",
              title: "Sign up with us",
              points: "5000 points",
            },
            {
              img: "./images/getGiftImage3.png",
              title: "Sign up with us",
              points: "5000 points",
            },
            {
              img: "./images/getGiftImage4.png",
              title: "Sign up with us",
              points: "5000 points",
            },
            {
              img: "./images/getGiftImage2.png",
              title: "Sign up with us",
              points: "5000 points",
            },
          ].map((item, index) => (
            <div className=" col-md-4 col-sm-6 mt-4" key={index}>
              <div className="discussion-wrapper">
                <div className="ri-wrapper">
                  <div className="img">
                    <img src={item.img} alt={item.title} />
                  </div>
                </div>

                <div className="text">
                    <NavLink to="/blog1">
                  <h3 className="h5">{item.title}</h3>
                  </NavLink>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Discussion;
